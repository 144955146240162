// Generated by Framer (b993cbf)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";

const cycleOrder = ["lFmSaEpc6", "HoQ0jGgts"];

const variantClassNames = {HoQ0jGgts: "framer-v-140fdg1", lFmSaEpc6: "framer-v-42f6i9"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {"Horizontal Dark": "HoQ0jGgts", "Horizontal Light": "lFmSaEpc6"};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; logoLight?: {src: string; srcSet?: string}; logoDark?: {src: string; srcSet?: string} }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style, className, width, height, layoutId, variant: outerVariant = "lFmSaEpc6", logoLight: tlUffrKnL = {src: new URL("assets/2048/fjMyJjHtqW7UbZrZXQNa0lEYf0.png", import.meta.url).href}, logoDark: zlijfV7vQ = {src: new URL("assets/2048/UPq11QPPt8Stco6mWtqC9nvUBQk.png", import.meta.url).href}, ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "lFmSaEpc6", transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const isDisplayed = () => {
if (baseVariant === "HoQ0jGgts") return true
return false
}

const isDisplayed1 = () => {
if (baseVariant === "HoQ0jGgts") return false
return true
}

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-sSAxc", classNames)} style={{display: "contents"}}>
<motion.div {...restProps} className={cx("framer-42f6i9", className)} data-framer-name={"Horizontal Light"} layoutDependency={layoutDependency} layoutId={"lFmSaEpc6"} ref={ref} style={{...style}} transition={transition} {...addPropertyOverrides({HoQ0jGgts: {"data-framer-name": "Horizontal Dark"}}, baseVariant, gestureVariant)}>{isDisplayed() && (<Image background={{alt: "", fit: "fit", intrinsicHeight: 588, intrinsicWidth: 3455, pixelHeight: 588, pixelWidth: 3455, ...toResponsiveImage(zlijfV7vQ)}} className={"framer-1vbhmxu"} data-framer-name={"Horizontal Dark"} layoutDependency={layoutDependency} layoutId={"I635:15747;265:57"} transition={transition} {...addPropertyOverrides({HoQ0jGgts: {background: {alt: "", fit: "fit", intrinsicHeight: 588, intrinsicWidth: 3455, pixelHeight: 588, pixelWidth: 3455, sizes: "157.5px", ...toResponsiveImage(zlijfV7vQ)}}}, baseVariant, gestureVariant)}/>)}{isDisplayed1() && (<Image background={{alt: "", fit: "fit", intrinsicHeight: 400, intrinsicWidth: 2355, pixelHeight: 400, pixelWidth: 2355, sizes: "157.5px", ...toResponsiveImage(tlUffrKnL)}} className={"framer-fp7gft"} data-framer-name={"Horizontal Light"} layoutDependency={layoutDependency} layoutId={"I27332:27982;265:57"} transition={transition}/>)}</motion.div>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-sSAxc [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-sSAxc .framer-by7xj { display: block; }", ".framer-sSAxc .framer-42f6i9 { height: 40px; overflow: visible; position: relative; width: 158px; }", ".framer-sSAxc .framer-1vbhmxu, .framer-sSAxc .framer-fp7gft { bottom: 0px; flex: none; left: 0px; position: absolute; right: 0px; top: 0px; }", ".framer-sSAxc.framer-v-140fdg1 .framer-42f6i9 { aspect-ratio: 3.9375 / 1; height: var(--framer-aspect-ratio-supported, 40px); }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 40
 * @framerIntrinsicWidth 157.5
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"HoQ0jGgts":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"tlUffrKnL":"logoLight","zlijfV7vQ":"logoDark"}
 */
const FramervFvD580bU: React.ComponentType<Props> = withCSS(Component, css, "framer-sSAxc") as typeof Component;
export default FramervFvD580bU;

FramervFvD580bU.displayName = "Branding / Logo";

FramervFvD580bU.defaultProps = {height: 40, width: 157.5};

addPropertyControls(FramervFvD580bU, {variant: {options: ["lFmSaEpc6", "HoQ0jGgts"], optionTitles: ["Horizontal Light", "Horizontal Dark"], title: "Variant", type: ControlType.Enum}, tlUffrKnL: {__defaultAssetReference: "data:framer/asset-reference,fjMyJjHtqW7UbZrZXQNa0lEYf0.png?originalFilename=Logo+Light.png&preferredSize=large", title: "Logo Light", type: ControlType.ResponsiveImage}, zlijfV7vQ: {__defaultAssetReference: "data:framer/asset-reference,UPq11QPPt8Stco6mWtqC9nvUBQk.png?originalFilename=Logo+Dark.png&preferredSize=large", title: "Logo Dark", type: ControlType.ResponsiveImage}} as any)

addFonts(FramervFvD580bU, [])